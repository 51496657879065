<template lang="pug">
.container
  b-card.border-0
    .d-flex.justify-content-center
      h1 {{ profile.name }}
    full-calendar(ref="calendar" :options="calendarOptions")
      template(v-slot:eventContent="arg")
        .d-flex.justify-content-center {{ arg.event.title }}
    .d-flex.justify-content-end.align-items-center.mt-3
      div
        b-button.mr-1(
          variant="primary"
          @click="showSaveConfirm"
          :disabled="events.added.length === 0"
        ) Submit
        b-button.mr-1(
          variant="primary"
          @click="openRegularScheduleModal"
          :disabled="events.added.length === 0"
        ) Submit Timeslot Repitition
        b-button.mr-1(
          variant="danger"
          @click="onShowLeave"
          :disabled="events.leave.length === 0"
        ) Leave Application
  b-modal(
    v-model="modal.repitition"
    header-bg-variant="warning"
    header-text-variant="dark"
    cancel-title="No"
    ok-title="Yes"
    title="Submit Timeslot Repitition"
    @ok="saveRegularSchedule"
    @close="resetRegularScheduleRepitition"
    @cancel="resetRegularScheduleRepitition"
    :no-close-on-backdrop="true"
    :ok-disabled="parseInt(regularSchedule.weeks) === 1 && parseInt(regularSchedule.days) === 1"
  )
    b Enter the length (days/weeks) of timeslot repitition (including the first day).
    b-form-group(label="Days:")
      b-form-select.col-xs-12(
        v-model="regularSchedule.days"
        :options="[1, 2, 3, 4, 5, 6, 7]"
        label="Days"
        :disabled="!isOneDay || parseInt(regularSchedule.weeks) !== 1"
      )
    b-form-group(label="Weeks:")
      b-form-input.col-xs-12(
        v-model="regularSchedule.weeks"
        label="Weeks"
        type="number"
        :min="1"
        :disabled="parseInt(regularSchedule.days) !== 1"
      )
  b-modal(
    v-model="modal.leave"
    header-bg-variant="danger"
    title="Leave Application Confirm"
    :no-close-on-backdrop="true"
    :ok-disabled="false"
    @ok="onSubmitLeave"
    @close="resetLeaveSubmit"
    @cancel="resetLeaveSubmit"
  )
    b-form-group(label="Time List" label-for="timeUl")
      ul#timeUl
        li(v-for="event in events.leave")
          p.m-0 {{ `${event.startMoment.format('YYYY-MM-DD HH:mm')}~${event.endMoment.format('HH:mm')}` }}
    b-form-group(label="Leave")
      b-form-select(v-model="leave.type" :options="leave.options")
    b-form-group(
      label="Reason"
    )
      b-form-textarea(
        v-validate="'required'"
        v-model="leave.reason"
        name="note"
        :rows="3"
        placeholder="please enter note"
      )
    b-form-group(
      label="Upload file"
    )
      b-form-file(
        v-model="leave.files"
        multiple
        accept="image/*"
        ref="leavePictureFiles"
        placeholder="Choose a file..."
      )
</template>

<script>
/* eslint-disable no-unused-vars */

import FullCalendar from '@fullcalendar/vue';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import TimeGridPlugin from '@fullcalendar/timegrid';

import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

import WuwowLoadingMixin from '@/components/wuwowLoading/mixin/index.vue';

import { uniqueId } from 'lodash';
import moment from 'moment';

import {
  getColorAndTitle,
  getRestTime,
  isTimeRangeInRestTime,
  isPastOrCurrent,
  STATUS,
} from '@/utils/schedule';
import api from '@lioshutan/api-package';
import storage from '@/utils/localStorage';

const DISPLAY = 'Display';
const DELETE = 'Delete';
const ADD = 'Add';

export default {

  components: {
    FullCalendar,
  },

  mixins: [WuwowLoadingMixin],

  data() {
    return {
      leave: {
        options: [
          {
            value: null,
            text: 'Please choose a option...',
            disabled: true,
          },
          {
            value: 'sick',
            text: 'sick',
          },
          {
            value: 'personal',
            text: 'personal',
          },
          {
            value: 'others',
            text: 'others',
          },
        ],
        type: null,
        reason: '',
        files: [],
      },
      modal: {
        leave: false,
        repitition: false,
      },
      regularSchedule: {
        days: 1,
        weeks: 1,
        show: false,
      },
      calendarApi: null,
      start: null,
      end: null,
      token: '',
      now: '',
      // All of Moment objects should be formatted to this format.
      timeFormat: 'YYYY-MM-DD HH:mm',
      // The forbidden area displayed in the calendar.
      forbid: {
        title: 'NO CLASS',
        start: '00:30',
        end: '09:00',
        rendering: 'background',
        overlap: false,
        color: '#ff9f89',
      },
      events: {
        added: [],
        deleted: [],
        leave: [],
      },

      profile: {
        name: '',
      },
      calendarOptions: {
        // initialDate: new Date(2021, 11, 26),
        // visibleRange: {
        //   start: new Date(2021, 12, 26),
        //   end: new Date(2022, 1, 1),
        // },
        plugins: [
          dayGridPlugin,
          interactionPlugin,
          TimeGridPlugin,
        ],
        allDaySlot: false,
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,today',
        },
        selectOverlap: false,
        initialView: 'timeGridWeek',
        // locale: 'tw',
        selectable: true,
        select: this.handleDateSelect,
        eventAdd: this.eventAdd,
        eventDisplay: 'block',
        events: this.handleEvents,
        // https://fullcalendar.io/docs/eventClick < eventClick文件
        eventClick: this.handleEventClick,
        // eventsSet: this.handleEvents,
        eventChange: this.eventChange,
        eventRemove: this.eventRemove,
        /* you can update a remote database when these fire:
        */
      },
      currentEvents: [],
    };
  },
  computed: {
    /**
     * 判斷排成的部分是否為一天的行程
     */
    isOneDay() {
      const allDate = this.events.added.map((add) => moment(add.start).format('yyyyMMDD'));

      return new Set(allDate).size === 1;
      // return null;
    },
  },
  async created() {
    this.token = this.$store.state.auth.token;
    this.getServerTime();
  },
  mounted() {
    this.calendarApi = this.$refs['calendar']['getApi']();
    // if (this.$route.query['date']) {
    //   this.$refs['calendar']['getApi']()['gotoDate'](this.$route.query['date']);
    // }
  },
  methods: {
    refreshSchedule() {
      // TODO: 這邊要做重新刷新行事曆的動作
      location.reload();
    },
    resetLeaveSubmit() {
      this.leave.type = null;
      this.leave.reason = '';
      this.leave.files = [];
    },
    async onSubmitLeave() {
      const tag = this.startLoading();
      await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .consultant(this.token)
        .consultantLeaveRequest(
          this.events.leave.map((data) => data.id),
          4,
          this.leave.type,
          this.leave.reason,
          this.leave.files
        );
      this.endLoading(tag);
      this.refreshSchedule();
    },
    isAfterThreeDayAgo(date) {
      const afterThreeDay = moment(
        moment().add(3, 'days').format('yyyyMMDD')
      ).toDate();
      return moment(afterThreeDay).isBefore(date);
    },
    isStatusCheck(isTimeslotStatus) {
      const pbClass = 4;
      const demoClass = 5;
      const allowStatus = [pbClass, demoClass];
      return allowStatus.includes(isTimeslotStatus);
    },
    async updateSchedule(schedules) {
      try {
        const tag = this.startLoading();
        const response = await api
          .setDNS(process.env.VUE_APP_BASE_API)
          .consultant(this.token)
          .updateSchedule(schedules, []);
        this.refreshSchedule();
        this.endLoading(tag);
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * @param {array} schedules
     * @param {number} time
     * @param {string} unit
     */
    repeatSchedules(schedules, time, unit) {
      const result = [];
      Array(time).fill().forEach((data, index) => {
        if (index !== 0) {
          schedules.forEach((schedule) => {
            result.push({
              id: uniqueId('unsaved'),
              title: '等待送出',
              start: moment(schedule.start).add(index, unit).toDate(),
              end: moment(schedule.end).add(index, unit).toDate(),
            });
          });
        } else {
          result.push(...schedules);
        }
      });

      return result;
    },
    resetRegularScheduleRepitition() {
      this.regularSchedule.days = 1;
      this.regularSchedule.weeks = 1;
    },
    openRegularScheduleModal() {
      this.modal.repitition = true;
    },
    async saveRegularSchedule() {
      const unit = this.regularSchedule.weeks !== 1 ? 'weeks' : 'days';
      const time = this.regularSchedule.weeks !== 1
        ? this.regularSchedule.weeks
        : this.regularSchedule.days;

      const repititionSchedules = this.getAddedParams(
        this.repeatSchedules(
          this.events.added,
          parseInt(time),
          unit
        )
      );

      await this.updateSchedule(repititionSchedules);
    },
    /**
     * @param {array} events
     */
    getAddedParams(events) {
      const params = [];
      for (let i = 0; i < events.length; i++) {
        const target = events[i];
        if (target.id) {
          const start = moment(target.start);
          const end = moment(target.end);
          while (!start.isSame(end)) {
            let timestamp = start.format('YYYYMMDD');
            let timeslot = (moment(start.format('YYYY-MM-DD HH:mm')).subtract(9, 'hour').get('hour')) * 2;

            timeslot = (start.format('mm') === '00') ? timeslot : timeslot + 1;
            timeslot++;

            timestamp = (timeslot === 31) ? moment(timestamp, 'YYYYMMDD').subtract(1, 'day').format('YYYYMMDD') : timestamp;
            timestamp += ((timeslot > 9) ? timeslot : '0' + timeslot);
            params.push({
              timestamp: parseInt(timestamp, 10),
              timeslot: timeslot,
            });
            start.add(30, 'minutes');
          }
        }
      }
      return params;
    },
    async showSaveConfirm() {
      try {
        await swal.fire({
          title: 'Save Schedule Confirm',
          text: 'Are you sure you want to submit your schedule?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Submit',
        });

        const schedules = this.getAddedParams(this.events.added);
        await this.updateSchedule(schedules);
      } catch (error) {
        console.log('error', error);
      }
    },

    onShowLeave() {
      this.modal.leave = true;
    },

    async getServerTime() {
      // const response = await axios.get(process.env.API_BASE_URL + 'server-time');
      const response = await new Promise((resolve, reject) => {
        resolve({
          data: {
            data: moment().format('yyyy-MM-DD hh:mm:ss'),
          },
        });
      });
      this.now = response.data.data;
    },
    /**
     * 抓取所有行程
     */
    async handleEvents({ start, end }, success, fail) {
      this.start = start;
      this.end = end;
      try {
        const response = await this.getConsultantSchedulePerWeek({
          start,
          end,
        });
        success(response);
      } catch (error) {
        console.log(error);
        fail(error);
      }
    },

    /**
     * 取得顧問排程
     *
     * @returns {Promise<Array>} 顧問排成
     */
    async getConsultantSchedulePerWeek({ start, end }) {
      const tag = this.startLoading();

      const sunday = moment(start).format('YYYYMMDD');
      const saturday = moment(end).format('YYYYMMDD');
      this.id = this.$route.params['id'];

      const response = await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .consultant(storage.token)
        .getSchedule(`${sunday}01`, `${saturday}31`);

      // hbConsultantId: 1051
      // id: 906553
      // isPb: false
      // status: 1
      // timeslot: 13
      // timestamp: 2021122613
      // type: "1"

      const result = response.data.data.map(({
        id,
        status,
        timeslot,
        timestamp,
        type,
      }) => {
        const start = moment(
          `${timestamp.toString().substring(0, 8)}0830`,
          'YYYY-MM-DD HH:mm'
        ).add(timeslot * 30, 'minute');
        const end = moment(start.format('YYYY-MM-DD HH:mm')).add(30, 'minute');

        const result = {
          id,
          start: start.toISOString(),
          end: end.toISOString(),
          status,
          type: +type,
          timeslotStatus: status,
        };

        const colorAndTitle = getColorAndTitle(status, +type);
        return {
          backgroundColor: colorAndTitle.color,
          borderColor: colorAndTitle.color,
          title: `${moment(result.start).format('HH:mm')} ${colorAndTitle.title}`,
          ...result,
        };
      });

      result.push(...getRestTime(start, end).map((time) => ({
        ...time,
        display: 'background',
        color: '#ff9f89',
      })));

      this.endLoading(tag);

      return result;
    },

    /**
     * 針對未排定的部分動作
     */
    handleDateSelect({ start, end, startStr, endStr, view: { calendar }}) {
      if (isTimeRangeInRestTime(start, end)) {
        alert('您無法在休息時間排課！');
        return;
      }

      if (isPastOrCurrent(start)) {
        // alert('You can not add the event into past.');
        alert('該時間段無法排課！');
        return;
      }

      // const title = prompt('Please enter a new title for your event');
      const title = '等待送出';
      const calendarApi = calendar;
      calendarApi.unselect(); // clear date selection
      const id = uniqueId('unsaved');

      calendarApi.addEvent({
        id,
        title,
        start: startStr,
        end: endStr,
        // allDay: selectInfo.allDay,
      });
    },

    /**
     * 針對Event動作
     */
    async handleEventClick({
      el,
      event,
      // {
      //   id,
      //   groupId,
      //   title,
      //   display,
      //   extendedProps: {
      //     status,
      //     type,
      //     cusInfo,
      //   },
      //   start,
      //   end,
      //   remove,
      // },
      jsEvent,
      view,
    }) {
      // https://fullcalendar.io/docs/event-object < Event物件文件
      // console.log([event.id, event, event.backgroundColor, event.borderColor]);
      // event.backgroundColor = '#111111';
      // console.log([event.id, event.groupId, event.title, event.start, event.end, event.extendedProps]);

      const timeslotStatus = event.extendedProps.timeslotStatus;
      if (this.isStatusCheck(timeslotStatus)) {
        await swal.fire({
          title: 'Message HR of PM to apply for leave',
          icon: 'warning',
        });

        return;
      }

      const eventStart = event.start;
      const eventEnd = event.end;
      if (isTimeRangeInRestTime(eventStart, eventEnd)) {
        return;
      }

      const eventId = event.id;
      if (eventId.includes('unsaved')) {
        event.remove();
        return;
      }

      const eventTitle = event.title;
      if (this.isAfterThreeDayAgo(eventStart)) {
        if (this.events.leave.map((target) => target.id).includes(eventId)) {
          this.events.leave = this.events.leave.filter((target) => target.id !== eventId);
          // TODO: 該部分要把顏色變回來
        } else {
          this.events.leave.push({
            start: eventStart,
            end: eventEnd,
            id: eventId,
            title: eventTitle,
            startMoment: moment(eventStart),
            endMoment: moment(eventEnd),
          });
          // TODO: 該部分要變更行程顏色
        }

        return;
      }

      if (!this.isAfterThreeDayAgo(eventStart)) {
        await swal.fire({
          title: 'Message HR of PM to apply for leave',
          icon: 'warning',
        });

        return;
      }

      if (isPastOrCurrent(eventStart)) {
        // alert('You can not delete the past event.');
        alert('您不允許修改過去的行程！');
        return;
      }

      if ([STATUS.CLASS, STATUS.DEMO, STATUS.PENDING].includes(event.extendedProps.status)) {
        // alert('You can not change this event status.');
        alert('您不允許修改該行程的狀態！');
        return;
      }

      // if (confirm(`Are you sure you want to delete the event '${event.title}'`)) {
      //   event.remove();
      // }
    },
    /**
     * https://fullcalendar.io/docs/eventAdd
     */
    eventAdd({ event, relatedEvents, revert }) {
      this.events.added.push({
        id: event.id,
        title: event.title,
        start: event.startStr,
        end: event.endStr,
      });
    },
    /**
     * https://fullcalendar.io/docs/eventRemove
     */
    eventRemove({ event, relatedEvents, revert }) {
      this.events.added = this.events.added.filter((add) => add.id !== event.id);
    },

    eventChange(data) {
      // TODO: 還不知道能做什麼
    },

    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
  },
};
</script>
