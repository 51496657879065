var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('b-card', {
    staticClass: "border-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('h1', [_vm._v(_vm._s(_vm.profile.name))])]), _c('full-calendar', {
    ref: "calendar",
    attrs: {
      "options": _vm.calendarOptions
    },
    scopedSlots: _vm._u([{
      key: "eventContent",
      fn: function (arg) {
        return [_c('div', {
          staticClass: "d-flex justify-content-center"
        }, [_vm._v(_vm._s(arg.event.title))])];
      }
    }])
  }), _c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-3"
  }, [_c('div', [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary",
      "disabled": _vm.events.added.length === 0
    },
    on: {
      "click": _vm.showSaveConfirm
    }
  }, [_vm._v("Submit")]), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary",
      "disabled": _vm.events.added.length === 0
    },
    on: {
      "click": _vm.openRegularScheduleModal
    }
  }, [_vm._v("Submit Timeslot Repitition")]), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "danger",
      "disabled": _vm.events.leave.length === 0
    },
    on: {
      "click": _vm.onShowLeave
    }
  }, [_vm._v("Leave Application")])], 1)])], 1), _c('b-modal', {
    attrs: {
      "header-bg-variant": "warning",
      "header-text-variant": "dark",
      "cancel-title": "No",
      "ok-title": "Yes",
      "title": "Submit Timeslot Repitition",
      "no-close-on-backdrop": true,
      "ok-disabled": parseInt(_vm.regularSchedule.weeks) === 1 && parseInt(_vm.regularSchedule.days) === 1
    },
    on: {
      "ok": _vm.saveRegularSchedule,
      "close": _vm.resetRegularScheduleRepitition,
      "cancel": _vm.resetRegularScheduleRepitition
    },
    model: {
      value: _vm.modal.repitition,
      callback: function ($$v) {
        _vm.$set(_vm.modal, "repitition", $$v);
      },
      expression: "modal.repitition"
    }
  }, [_c('b', [_vm._v("Enter the length (days/weeks) of timeslot repitition (including the first day).")]), _c('b-form-group', {
    attrs: {
      "label": "Days:"
    }
  }, [_c('b-form-select', {
    staticClass: "col-xs-12",
    attrs: {
      "options": [1, 2, 3, 4, 5, 6, 7],
      "label": "Days",
      "disabled": !_vm.isOneDay || parseInt(_vm.regularSchedule.weeks) !== 1
    },
    model: {
      value: _vm.regularSchedule.days,
      callback: function ($$v) {
        _vm.$set(_vm.regularSchedule, "days", $$v);
      },
      expression: "regularSchedule.days"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Weeks:"
    }
  }, [_c('b-form-input', {
    staticClass: "col-xs-12",
    attrs: {
      "label": "Weeks",
      "type": "number",
      "min": 1,
      "disabled": parseInt(_vm.regularSchedule.days) !== 1
    },
    model: {
      value: _vm.regularSchedule.weeks,
      callback: function ($$v) {
        _vm.$set(_vm.regularSchedule, "weeks", $$v);
      },
      expression: "regularSchedule.weeks"
    }
  })], 1)], 1), _c('b-modal', {
    attrs: {
      "header-bg-variant": "danger",
      "title": "Leave Application Confirm",
      "no-close-on-backdrop": true,
      "ok-disabled": false
    },
    on: {
      "ok": _vm.onSubmitLeave,
      "close": _vm.resetLeaveSubmit,
      "cancel": _vm.resetLeaveSubmit
    },
    model: {
      value: _vm.modal.leave,
      callback: function ($$v) {
        _vm.$set(_vm.modal, "leave", $$v);
      },
      expression: "modal.leave"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Time List",
      "label-for": "timeUl"
    }
  }, [_c('ul', {
    attrs: {
      "id": "timeUl"
    }
  }, _vm._l(_vm.events.leave, function (event) {
    return _c('li', [_c('p', {
      staticClass: "m-0"
    }, [_vm._v(_vm._s(`${event.startMoment.format('YYYY-MM-DD HH:mm')}~${event.endMoment.format('HH:mm')}`))])]);
  }), 0)]), _c('b-form-group', {
    attrs: {
      "label": "Leave"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.leave.options
    },
    model: {
      value: _vm.leave.type,
      callback: function ($$v) {
        _vm.$set(_vm.leave, "type", $$v);
      },
      expression: "leave.type"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Reason"
    }
  }, [_c('b-form-textarea', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "name": "note",
      "rows": 3,
      "placeholder": "please enter note"
    },
    model: {
      value: _vm.leave.reason,
      callback: function ($$v) {
        _vm.$set(_vm.leave, "reason", $$v);
      },
      expression: "leave.reason"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Upload file"
    }
  }, [_c('b-form-file', {
    ref: "leavePictureFiles",
    attrs: {
      "multiple": "",
      "accept": "image/*",
      "placeholder": "Choose a file..."
    },
    model: {
      value: _vm.leave.files,
      callback: function ($$v) {
        _vm.$set(_vm.leave, "files", $$v);
      },
      expression: "leave.files"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }